import React, { useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { AiOutlineArrowRight } from 'react-icons/ai';
import './Slider.scss';
import useFetch from '../../hooks/useFetch';

const Slider = () => {
    
    const [currentSlide, setCurrntSlide] = useState(0);

    const {data, loading, error} = useFetch(
        `/carousels/10?populate=deep`
    );

    // const {data, loading, error} = useFetch(
    //     `/carousels/1?populate=deep`
    // );

    // const data = [
    //     "https://images.pexels.com/photos/7081105/pexels-photo-7081105.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    //     "https://images.pexels.com/photos/949670/pexels-photo-949670.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    //     "https://images.pexels.com/photos/7335321/pexels-photo-7335321.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // ];

    const prevSlide = () => {
        setCurrntSlide(currentSlide === 0 ? 2 : (prev) => prev - 1);
    };

    const nextSlide = () => {
        setCurrntSlide(currentSlide === 2 ? 0 : (prev) => prev + 1);
    };

    return (
        <div className='slider'>
            <div className='container' style={{transform: `translateX(-${currentSlide * 100}vw)`}}>
                {data?.attributes?.carousel?.data?.map((img) => (
                    <img 
                        src={img?.attributes?.url} 
                        alt=""
                        key={img?.id}
                    />
                ))}
            </div>
            <div className='icons'>
                <div className="icon" onClick={prevSlide}>
                    <AiOutlineArrowLeft />
                </div>
                <div className="icon" onClick={nextSlide}>
                    <AiOutlineArrowRight />
                </div>
            </div>
        </div>
    )
}

export default Slider