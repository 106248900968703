import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import List from '../../components/List/List';
import './WardrobePage.scss';
import useFetch from '../../hooks/useFetch';
import Card from '../../components/Card/Card';
import Wardrobe from '../../components/Wardrobe/Wardrobe';
import { ImBin } from 'react-icons/im';
// import { removeItem, resetWardrobe } from '../../redux/wardrobeReducer';
import axios from 'axios';
import { userData } from '../../helpers';
import useWardrobe from './useWardrobe';
import { toast } from 'react-toastify';

const WardrobePage = ({wardrobe, removeFromWardrobe}) => {
  const navigate = useNavigate();

  const totalPrice = () => {
    let total = 0
    wardrobe.forEach(item => total += item.price)
    return total.toFixed(2);
  }

  return (
    <div className='wardrobepage'>
      {wardrobe?.length < 0 ? (
        <div className="empty-basket">
          <h3>Wardrobe is empty</h3>
          <button
            className="home-btn"
            onClick={() => {
              navigate("/");
            }}
          >
            Go to home
          </button>
        </div>
      ) : (
        <>
          <div className='column'>
            {wardrobe?.map(item => (
              <>
                <div className='link' key={item.id}>
                  <Link className='item' to={`${item.link}`}>
                    {item?.attributes?.img?.data?.map((item, key) => (
                        <img 
                            src={item?.img} 
                            alt="" 
                            className={key === 0 ? "mainImg" : "secondImg"}
                        />
                    ))}
                    <img src={item.img} alt="" />
                    <div className='details'>
                        <h1>{item.title}</h1>
                        <p>{item?.desc?.substring(0, 100)}...</p>
                        <div className="d-flex-space">
                          <div className="price">From: £{item.price}</div>
                        </div>
                    </div>
                  </Link>
                  <ImBin className="delete" 
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFromWardrobe({
                        wardrobeItemId: item.id,
                        index: item.id,
                        id: item.id
                      })
                    }}
                  />
                </div>
              </>
            ))}
          </div>
          <div className='wardrobe-footer'>
            <div className="total">
              <span>Potential Total Cost</span>
              <span>£{totalPrice()}</span>
            </div>
            <div className="d-block">
              {/* <div className="reset" onClick={() => dispatch(resetWardrobe())}>Clear out wardrobe</div> */}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default WardrobePage