import React from 'react';
import Card from '../Card/Card';
import './FeaturedProducts.scss';
import useFetch from '../../hooks/useFetch';
import { Puff } from 'react-loader-spinner';

const FeaturedProductsDesc = ({type}) => {

  const {data, loading, error} = useFetch(
    `/featured`
  );

  return (
    <p>
       {data?.attributes?.description}
    </p>
  )
}

export default FeaturedProductsDesc