import React, { useState } from 'react'
import Card from '../Card/Card'
import "./List.scss"
import useFetch from '../../hooks/useFetch'
import { Puff } from 'react-loader-spinner'

const List = ({subCats, maxPrice, sort, catId, query}) => {

  const {data, loading, error} = useFetch(
    `/products?populate=*&[filters][categories][id]=${catId}${subCats.map(
      (item) => `&[filters][sub_categories][id][$eq]=${item}`
    )}&[filters][price][$lte]=${maxPrice}&sort=price:${sort}`
  );

  const [visible, setVisible] = useState(20);

  const showMoreProducts = () => {
    setVisible(prevValue => prevValue + 20);
  }

  return (
    <>
    <div className='list'>
        {data?.filter((item) => item?.attributes?.title?.toLowerCase()?.includes(query)).slice(0, visible).map(item => (
          <Card item={item} key={item.id} />
        ))}
    </div>
    <button
      className="load-more-btn"
      onClick={showMoreProducts}
    >
      Load More
    </button>
    </>
  )
}

export default List