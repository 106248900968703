import React, { useEffect } from 'react';
import { default as RegisterComponent } from '../../components/Register/Register';
import { useFetchUser } from '../../lib/authContext';

const Register = () => {
  
  const {user, loading} = useFetchUser();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div user={user}>
      <RegisterComponent />
    </div>
  )
}

export default Register