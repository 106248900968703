import React from 'react';
import './Categories.scss';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { Puff } from 'react-loader-spinner';

const Categories = () => {
    const {data, loading, error} = useFetch(
        `/categories?populate=deep`
    );

    return (
        <div className='categories'>
            {loading ? ( 
                <div className='loading'>
                    <Puff
                        height="80"
                        width="80"
                        radius={1}
                        color="#757575"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div> 
            ) : (
                <>
                    <div className='col'>    
                    {data?.slice(0, 2).map((item) => (   
                        <div className='row' key={item.id}>
                            <img src={item?.attributes?.img?.data?.attributes?.url} alt="" />
                            <button>
                                <Link className='link' to={`/products/${item.id}`}>{item.attributes.title}</Link>
                            </button>
                        </div>
                    ))}
                    </div>
                    <div className='col'>
                    {data?.slice(2, 3).map((item) => (   
                        <div className="row" key={item.id}>
                            <img src={item?.attributes?.img?.data?.attributes?.url} alt="" />
                            <button>
                                <Link className='link' to={`/products/${item.id}`}>{item.attributes.title}</Link>
                            </button>
                        </div>
                    ))}
                    </div>
                    <div className='col col-l'>
                        <div className="row">
                            {data?.slice(3, 5).map((item) => (   
                                <div className="col" key={item.id}>
                                    <div className="row">
                                        <img src={item?.attributes?.img?.data?.attributes?.url} alt="" />
                                        <button>
                                            <Link className='link' to={`/products/${item.id}`}>{item.attributes.title}</Link>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {data?.slice(5, 6).map((item) => (   
                            <div className="row">
                                <img src={item?.attributes?.img?.data?.attributes?.url} alt="" />
                                <button>
                                    <Link className='link' to={`/products/${item.id}`}>{item.attributes.title}</Link>
                                </button>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default Categories