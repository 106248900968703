import React, { useEffect } from 'react';
import { default as LoginComponent } from '../../components/Login/Login';
import { useFetchUser } from '../../lib/authContext';
import { default as ThankyouComponent } from '../../components/Thankyou/Thankyou';

const Thankyou = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ThankyouComponent />
    </div>
  )
}

export default Thankyou