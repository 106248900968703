import React, { useEffect } from 'react';
import { default as LoginComponent } from '../../components/Login/Login';
import { useFetchUser } from '../../lib/authContext';

const Login = () => {

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div >
      <LoginComponent />
    </div>
  )
}

export default Login