import React, { useEffect, useState } from 'react';
import './Footer.scss';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import axios from 'axios';

const Footer = () => {
    const {data, loading, error} = useFetch(
        `/categories`
    );

    const [about, setAbout] = useState({});
    const [contact, setContact] = useState({});

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/about-footer`)
        .then((response) => {
            setAbout(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/contact-footer`)
        .then((response) => {
            setContact(response.data);
        })
        .catch((error) => {
            console.error(error);
        });
    }, [])

    return (
        <div className='footer'>
            <div className='top'>
                <div className='item'>
                    <h1>Categories</h1>
                    {data?.map((item) => (
                        <span key={item.id}><Link to={`/products/${item.id}`}>{item.attributes.title}</Link></span>
                    ))}
                </div>
                <div className="item">
                    <h1>Links</h1>
                    <span><Link to="/signin">Sign in</Link></span>
                    <span><Link to="/register">Register</Link></span>
                    <span><Link target="_blank" to="https://instagram.com/theartfulstylist?igshid=MzRlODBiNWFlZA==">Instagram</Link></span>
                    {/* <span>Stores</span>
                    <span>Compare</span>
                    <span>Cookies</span> */}
                </div>
                <div className='item'>
                    <h1>About</h1>
                    <span>
                       {about?.data?.attributes?.description}
                    </span>
                </div>
                <div className='item'>
                    <h1>Contact</h1>
                    <span>
                        <a href={`mailto:${contact?.data?.attributes?.email}`}>{contact?.data?.attributes?.email}</a>
                    </span>
                </div>
            </div>
            <div className='bottom'>
                <div className="left">
                    <span className="logo"><img src='/img/text-logo.svg' alt='logo' /></span>
                    <span className="copyright">
                        © Copyright 2023. All Rights Reserved
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer