import { useEffect, useState } from 'react';
import { setToken } from '../../lib/auth';
import { fetcher } from '../../lib/api';
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { storeUser } from "../../helpers";
import './ForgotPassword.scss';

const initialUser = { email: "" };

const ForgotPassword = () => {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
      email: user.email, // user's email
    })
    .then(response => {
      if (user.email) {
        if(response) {
          toast.success("Your user received an email!", {
            hideProgressBar: true,
          });
        }
      } else {
        toast.error("Email is required!", {
          hideProgressBar: true,
        });
      }
    })
    .catch(error => {
      const errorMessageDisplay = error.response.data.error.message;

      toast.error(
        errorMessageDisplay, {
          hideProgressBar: true,
        }
      );
    });
  };

  return (
    <div className="forgot">
      <h1>
        <span>
          Forgot Password
        </span>
      </h1>
      <form>
        <div className="container">
          <div className="flex flex-col mb-4 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={user.email}
              onChange={handleChange}
              placeholder="Enter email"
              required
            />
          </div>
          <button
            className="submitbtn"
            type="submit"
            onClick={handleForgotPassword}
          >
            Sumbit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;