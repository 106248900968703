import React, { useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosSearch, IoIosPerson, IoIosHeartEmpty } from 'react-icons/io';
import { GiShoppingCart } from 'react-icons/gi';
import { BsDoorOpen } from 'react-icons/bs';
import Flag from 'react-flagkit';
import { Link } from 'react-router-dom';
import './Navbar.scss';
import '../Login/Login.scss';
import Cart from '../Cart/Cart';
import Wardrobe from '../Wardrobe/Wardrobe';
import { userData } from '../../helpers';
import LoginButton from '../Login/Login';
import LogoutButton from '../Logout/LogoutButton';

const Navbar = ({wardrobe, wardrobeCount, setWardrobeCount, removeFromWardrobe}) => {
    const [open, setOpen] = useState(false);
    const { username, jwt } = userData();
    const isLoggedIn = !!jwt;
    // const products = useSelector(state => state.wardrobe.products);
    
    return (
        <div className='navbar'>
            <div className="wrapper">
                <div className={`${username ? 'left' : ''}`}>
                    <div className='item'>
                        <p>{username}</p>
                    </div>
                </div>
                <div className='center'>
                    <Link to='/'><img src='/img/text-logo.svg' alt='logo'/></Link>
                </div>
                <div className='right'>
                    <div className='icons'>
                        <Link to='/products/51'>
                            <IoIosSearch />
                        </Link>
                        {isLoggedIn ?
                            <>
                                <div className='item'>
                                
                                    <LogoutButton />
                                
                                </div> 
                                <div className='cartIcon' onClick={() => setOpen(!open)}>
                                    <BsDoorOpen />
                                    <span>{wardrobe.length}</span>
                                </div>
                            </>
                            :
                            <Link to="/signin">
                                <IoIosPerson />
                            </Link>
                        } 
                    </div>
                </div>
            </div>
            {open && <Wardrobe open={open} setOpen={setOpen} wardrobe={wardrobe} removeFromWardrobe={removeFromWardrobe} />}
        </div>
    )
}

export default Navbar