import React from 'react';
import { Link } from 'react-router-dom';
import './Card.scss';

const Card = ({item}) => {
  return (
    <Link className='link' to={`/product/${item.id}`}>
        <div className='card'>
            <div className='image'>
                {item?.attributes.isNew && <span>New Season</span>}
                {item?.attributes?.img?.data?.map((item, key) => (
                    <img 
                        key={item.id}
                        src={item?.attributes?.url} 
                        alt="" 
                        className={key === 0 ? "mainImg" : "secondImg"}
                    />
                ))}
            </div>
            <h2>
                {item?.attributes.title}
            </h2>
            <div className="prices">
                <h3>£{item.oldPrice || item?.attributes.price + 20}</h3>
                <h3>£{item?.attributes.price}</h3>
            </div>
        </div>
    </Link>
  )
}

export default Card