import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './Logout.scss';

const Logout = () => {
  const [signOut, setSignOut] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    if(signOut) {
      localStorage.setItem("user", "");

      navigate("/");
      window.location.reload();
    }
  }, [signOut]);

  const signOutHandler = (e) => {
    e.preventDefault()
    setSignOut(true)
  }

  return (
    <button className='logoutbtn' onClick={signOutHandler}>Sign out</button>
  );
};

export default Logout;