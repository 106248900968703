import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { storeUser } from "../../helpers";
import './Thankyou.scss';

const Thankyou = () => {
  return (
    <div className="thankyou">
      <h1>
        <span>
          Thank you for confirming your account
        </span>
      </h1>
      <br />
      <Link to="/signin">Please Sign in</Link>
    </div>
  );
};

export default Thankyou;