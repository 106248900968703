import React, { useState } from 'react';
import './Contact.scss';
import { AiOutlineFacebook, AiOutlineInstagram } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import usePost from '../../hooks/usePost';

const Contact = () => {
  const [email, setEmail] = useState('');

  const { signup, signupError, signupMsg, signupLoading, handleChange, handleSignup } = usePost(
    `${process.env.REACT_APP_API_URL}/email`
  );

  const sendEmailOnClick = async () => {
    if (email) {
      try {
        const response = await axios.post('/send-email', { email });
        if (response.data.success) {
          toast.success('Thank you for joining us!');
          setEmail(''); // Clear the email input
        } else {
          toast.error('Email sending failed.');
        }
      } catch (error) {
        console.error('Email request failed:', error);
        toast.error('Email request failed.');
      }
    }
  };

  return (
    <div className='contact'>
        <div className='wrapper'>
            <span>BE IN TOUCH WITH US:</span>
            <div className="mail">
              {signupMsg ? <p> Thanks for signing up!</p> : 
                <form onSubmit={handleSignup}>
                  <label htmlFor="email">
                    {signupError ? <p> {signupError} </p> : null}
                    <input
                      type="text"
                      placeholder='Enter your e-mail...'
                      value={signup}
                      onChange={handleChange}
                      />
                      
                  </label>
                  <button>Join Us</button>
                </form>
              }
            </div>
            <div className="icons">
              <Link target="_blank" to="https://instagram.com/theartfulstylist?igshid=MzRlODBiNWFlZA=="><AiOutlineInstagram /></Link>
            </div>
        </div>
    </div>
  )
}

export default Contact