import React, { useEffect, useState } from 'react'
import './Product.scss';
import { GiShoppingCart } from 'react-icons/gi';
import { IoIosHeartEmpty } from 'react-icons/io';
import { BsCart, BsDoorOpen } from 'react-icons/bs';
import { FaBalanceScale } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
// import { addToWardrobe } from '../../redux/wardrobeReducer';
import axios from 'axios';
import { userData } from '../../helpers';
import { toast } from 'react-toastify';
import { Puff } from 'react-loader-spinner';

const Product = ({addToWardrobe, userId}) => {
  const id = useParams().id;

  const { jwt } = userData();

  const [wardrobe, setWardrobe] = useState([]);
  const [updateWardrobe, setUpdateWardrobe] = useState(false);

  const token = jwt;

  const navigate = useNavigate();

  const [selectedImg, setSelectedImg] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const {data, loading, error} = useFetch(
    `/products/${id}?populate=deep`
  );

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const addToWardrobeSubmit = async (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_API_URL}/wardrobes`;

    const { id } = userData();

    if(token) {
      addToWardrobe({
        id: data.id,
        title: data.attributes.title,
        desc: data.attributes.desc,
        price: data.attributes.price,
        link: data?.attributes?.url_links?.data[0]?.attributes?.link,
        img: data?.attributes?.img?.data[0]?.attributes?.url,
        quantity,
        user: id
      })
    } else {
      toast.error('You have to be logged in to add to wardrobe', {
        hideProgressBar: true,
      });
    }
  };

  console.log(data?.attributes?.products)
  
  return (
    <div className='product'>
      {loading ? ( 
        <div className='loading'>
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#757575"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /> 
        </div>
      ) : (
        <>
          <div className="left">
            <div className="images">
              {data?.attributes?.img?.data?.map((item, key) => (
                <div>
                  <img 
                    src={item?.attributes?.url} 
                    alt="" 
                    onClick={e => setSelectedImg(key)} 
                  />
                </div>
              ))}
            </div>
            <div className="mainImg">
              <img
                src={data?.attributes?.img?.data[selectedImg]?.attributes?.url}
                alt=""
              />
            </div>
          </div>
          <div className="right">
            <h1>{data?.attributes?.title}</h1>
            <span className="price">From: £{data?.attributes?.price}</span>
            <p>
                {data?.attributes?.desc}
            </p>
            {/* <div className="quantity">
              <button onClick={() => setQuantity(prev => prev === 1 ? 1 : prev - 1)}>-</button>
              {quantity}
              <button onClick={() => setQuantity(prev => prev + 1)}>+</button>
            </div> */}
            {data?.attributes?.products ? 
              <div className="links">
                {data?.attributes?.products?.data?.map((item, key) => (
                  <div className="add">
                    <a rel="noopener noreferrer" href={`${'/product/'+item?.id}`}>
                      <img className="images" src={item?.attributes?.img?.data[0]?.attributes?.url} alt="" />
                      <div className="title">{item?.attributes?.title}</div>
                    </a>
                    {item?.attributes?.url_links?.data?.map((singleitem, key) => (
                      <a rel="noopener noreferrer" target="_blank" href={singleitem?.attributes?.link} className="navigate-link">
                        Available @ {singleitem?.attributes?.shop}
                      </a>
                    ))}
                  </div>
                ))}
              </div> 
              : 
              null
            }

            {/* <button className="add">
              <GiShoppingCart /> ADD TO CART
            </button> */}
            {
              token ? (
                <>
                  <button className="add" onClick={addToWardrobeSubmit}>
                    <BsDoorOpen /> SAVE TO WARDROBE
                  </button>

                  {data?.attributes?.products.data.length === 0 && (
                    <button
                      onClick={() => {
                        const link = data?.attributes?.url_links?.data[0]?.attributes?.link;
                        if (link) {
                          window.open(link, '_blank');
                        } else {
                          console.error('Link not found');
                        }
                      }}
                      className="add"
                    >
                      <BsCart /> BUY
                    </button>
                  )}
                </>
              ) : (
                <button
                  className="add"
                  onClick={() => {
                    navigate('/signin');
                    toast.error('User needs to be signed in!');
                  }}
                >
                  <BsDoorOpen /> SAVE TO WARDROBE
                </button>
              )
            }
  
            <div className="links">
              {/* <div className="item">
                <IoIosHeartEmpty /> ADD TO WISHLIST
              </div> */}
              {/* <div className="item">
                <FaBalanceScale /> ADD TO COMPARE
              </div> */}
            </div>
            {/* <div className="info">
              <span>Vendor: Polo</span>
              <span>Product Type: T-Shirt</span>
              <span>Tag: T-Shirt, Women, Top</span>
            </div>
            <hr />
            <div className="info">
              <span>DESCRIPTION</span>
              <hr />
              <span>ADDITIONAL INFORMATION</span>
              <hr />
              <span>FAQ</span>
            </div> */}
          </div>
        </>
      )}
    </div>
  )
}

export default Product