import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { userData } from "../../helpers";

const useWardrobe = (token) => {
  const [wardrobe, setWardrobe] = useState([]);
  const [wardrobeCount, setWardrobeCount] = useState(0);
  const [updateWardrobe, setUpdateWardrobe] = useState(false);

  const { id } = userData();

  const userId = id;

  const getWardrobeData = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/users/${userId}?populate=deep`,
      {
        headers: {
          Authorization: `bearer ${token}`,
        },
      }
      );
      setUpdateWardrobe(false);
      const wardrobeData = data?.wardrobes?.map((item) => ({
        ...item,
        wardrobeItemId: item.id,
      }));
      setWardrobe(wardrobeData);
    } catch (error) {

      toast.error("Remove item error", {
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (!!token) {
        getWardrobeData();
    }
  }, [token, updateWardrobe]);

  const addToWardrobe = async ({
    id,
    title,
    desc,
    img,
    price,
    link,
    quantity,
    slug,
  }) => {
    const isSameProductExistingWardrobe = wardrobe.filter(
      (product) => 
        Number(product.id) === Number(id)
    );

    if (!isSameProductExistingWardrobe.length) {

      try {
        if (!!token) {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/wardrobes`,
            {
              data: {
                id: slug,
                title: title,
                desc: desc,
                img: img,
                price: price,
                link: link,
                quantity: 1,
                users: userId, // Set the category to the ID of the default category
              }
            },
            {
              headers: {
                Authorization: `bearer ${token}`,
              },
            }
          );
          setUpdateWardrobe(true);
          const updatedWardrobeCount = wardrobeCount + 1;
          setWardrobeCount(updatedWardrobeCount);
          localStorage.setItem('wardrobeCount', updatedWardrobeCount.toString());
        } else {

          setWardrobe([
            ...wardrobe,
            {
                title,
                desc,
                img,
                price,
                link,
                quantity: Number(quantity),
                id,
                users: userId
            },
          ]);
        }

        toast.success("Added to the wardrobe successfully!", {
          hideProgressBar: true,
        });
      } catch (error) {
        toast.error({error}, {
          hideProgressBar: true,
        });
      }
    } else {
      toast.error("Same product added to the wardrobe already!", {
        hideProgressBar: true,
      });
    }
  };

  const removeFromWardrobe = async ({ index, id, wardrobeItemId }) => {
    try {
      if (!!token) {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/wardrobes/${wardrobeItemId}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        );
        setUpdateWardrobe(true);
      } else {
        const basketAfterRemovedItem = wardrobe.filter(
          (item, idx) =>
            (item.id !== id && index !== idx) ||
            (item.id === id && index !== idx)
        );

        setWardrobe(basketAfterRemovedItem);
      }
    } catch (error) {
      toast.error("Remove item error", {
        hideProgressBar: true,
      });
    }
  };

  return { wardrobe, addToWardrobe, setWardrobe, wardrobeCount, removeFromWardrobe, setWardrobeCount };
};

export default useWardrobe;