import React, { useEffect } from 'react';
import { default as ForgotPasswordComponent } from '../../components/ForgotPassword/ForgotPassword';
import { useFetchUser } from '../../lib/authContext';

const ForgotPassword = () => {
  
  const {user, loading} = useFetchUser();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div user={user}>
      <ForgotPasswordComponent />
    </div>
  )
}

export default ForgotPassword