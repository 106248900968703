import React, { useEffect } from 'react';
import Categories from '../../components/Categories/Categories';
import Contact from '../../components/Contact/Contact';
import FeaturedProducts from '../../components/FeaturedPrdoucts/FeaturedProducts';
import Slider from '../../components/Slider/Slider';
import './Home.scss';
import NewIn from '../../components/FeaturedPrdoucts/NewInDesc';

const Home = () => {
  
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='home'>
      <Slider />
      <FeaturedProducts type="featured" />
      <Categories />
      <FeaturedProducts type="new in" />
      <Contact />
    </div>
  )
}

export default Home